import React from "react";
import "./App.css";
import mapboxgl from "mapbox-gl";
import { isMobileOnly } from "react-device-detect";
import Api from "./MWApi";

import OsloMap from "./OsloMap";
import InfoOverlay from "./components/InfoOverlay";
import MapFilter from "./components/MapFilter";
import Header from "./components/Header";
import IFLAInfo from "./components/IFLAInfo";
import LoadingIndicator from "./components/LoadingIndicator";
import { ColorFromString } from "./utils/ColorFromString";
import { withTranslation } from "react-i18next";

class IFLAMap extends React.Component {
  static initialZoomLevel = [13];
  map = null;
  api = null;

  state = {
    appLoading: true,
    pois: { type: "FeatureCollection", features: [] },
    allPois: { type: "FeatureCollection", features: [] },
    selectedMarker: null,
    center: [10.752245, 59.913868],
    zoom: [13],
    mapType: "map",
    style: "mapbox://styles/jensen2k/cjz8jkmch0g6c1cqnkmbz5vd8",
    selectedPoint: null,
    loadingPoint: false,
    filterCategories: [],
    filteredCategories: [],
    isFilterVisible: isMobileOnly ? false : true,
  };

  componentDidMount() {
    this.props.i18n.changeLanguage("en");

    this.api = new Api("https://www.oslobyleksikon.no");

    this.api.getPagePoisInCategory("Kategori:IFLA_2019").then((poiData) => {
      let pois = poiData
        .filter((p) => {
          if (p.coordinates !== undefined) {
            return true;
          }
          return false;
        })
        .map((p) => {
          return {
            type: "Feature",
            properties: {
              title: p.title,
              pageid: p.page.pageid,
              page: p.page,
              color: ColorFromString(p.page.title),
            },
            geometry: {
              type: "Point",
              coordinates: [p.coordinates.lon, p.coordinates.lat],
            },
          };
        });
      pois = {
        type: "FeatureCollection",
        features: pois,
      };

      this.setState({ pois: pois, allPois: pois, appLoading: false });
    });

    this.fetchFilterCategories();
  }

  fetchFilterCategories() {
    this.api.getSubcategories("Kategori:IFLA_2019").then((categories) => {
      categories = categories.map((cat) => {
        return {
          title: cat.title.replace("Walks+Talks: ", ""),
          categoryId: cat.categoryId,
        };
      });

      this.setState({ filterCategories: categories });
    });
  }

  onMapSearchDataRequest(value) {
    let pois = this.state.allPois.features
      .map((poi, idx) => {
        return {
          title: poi.properties.title,
          url: "url" + idx,
          type: "map",
          pageid: poi.properties.pageid,
          coordinates: poi.geometry.coordinates,
        };
      })
      .filter((poi) => {
        return poi.title.toLowerCase().startsWith(value.toLowerCase());
      });

    return pois ? pois : [];
  }

  onFilter(filter) {
    this.unselectMap(this.map);
    this.setState({
      selectedMarker: null,
      selectedPoint: null,
    });

    let categories = this.state.filterCategories.map((cat) => {
      if (cat === filter) {
        cat.selected = !cat.selected;
      } else if (cat.selected) {
        cat.selected = false;
      }

      return cat;
    });

    let filteredCategories = categories.filter((cat) => {
      return cat.selected;
    });

    this.setState({
      filterCategories: categories,
      filteredCategories: filteredCategories,
    });

    if (filteredCategories.length > 0) {
      let filteredCategoryIds = filteredCategories.map((f) => {
        return f.categoryId;
      });

      let filteredPois = this.state.allPois.features.filter((poi) => {
        if (filteredCategoryIds.includes(poi.properties.page.pageid)) {
          return true;
        } else {
          return false;
        }
      });
      var bounds = new mapboxgl.LngLatBounds();
      filteredPois.forEach((p) => {
        bounds.extend(p.geometry.coordinates, { padding: 100 });
      });
      if (filteredPois.length > 0) {
        this.map.fitBounds(bounds);
      }

      let geoJson = {
        type: "FeatureCollection",
        features: filteredPois,
      };
      this.setState({ pois: geoJson });
    } else {
      this.setState({
        pois: this.state.allPois,
        center: [10.752245, 59.913868],
        zoom: [13],
      });
    }
  }

  onMapClick(map) {
    if (this.state.selectedPoint != null) this.unsetSelectedMarker();
    this.unselectMap(map);
  }

  unsetSelectedMarker() {
    this.setState({ selectedPoint: null, selectedMarker: null });
  }

  onSearchPoiSelected = (poi) => {
    let coordinates = [poi.coordinates[0], poi.coordinates[1]];

    this.setState({
      loadingPoint: true,
      selectedMarker: poi.pageid,
      center: coordinates,
      zoom: [16],
    });

    this.setState({ loadingPoint: true });
    this.api.getPage(poi.pageid).then((page) => {
      page.title = page.title.replace("Walks+Talks", "");
      this.setState({ selectedPoint: page });
      this.setState({ loadingPoint: false });
    });
  };

  onMapLoad = (map) => {
    this.map = map;
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      })
    );
  };

  onFilterToggleClick() {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  }

  unselectMap = (map) => {
    if (typeof map.getLayer("selectedPoint") !== "undefined") {
      map.removeLayer("selectedPoint");
      map.removeSource("selectedPoint");
    }
  };

  onMarkerClick = (e) => {
    let map = e.target;
    var feature = e.features[0];
    this.unselectMap(map);

    map.addSource("selectedPoint", {
      type: "geojson",
      data: feature.toJSON(),
    });
    map.addLayer({
      id: "selectedPoint",
      type: "circle",
      source: "selectedPoint",
      paint: {
        "circle-color": "red",
        "circle-radius": 8,
        "circle-stroke-width": 4,
        "circle-stroke-color": "#fff",
      },
    });

    let pageid = feature.properties.pageid;

    this.setState({
      loadingPoint: true,
      selectedMarker: pageid,
    });

    this.api.getPage(pageid).then((page) => {
      page.title = feature.properties.title;

      this.setState({ selectedPoint: page });
      this.setState({ loadingPoint: false });
    });
  };

  shouldAllowTouch = () => {
    if (this.state.isFilterVisible || this.state.selectedPoint) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <div className={"App " + this.state.appType}>
        {this.state.appLoading ? <LoadingIndicator /> : null}
        <Header
          filterTitle="Select tour"
          onFilterToggleClick={() => this.onFilterToggleClick()}
          onSearchPoiSelected={(poi) => this.onSearchPoiSelected(poi)}
          onMapSearchDataRequest={(value) => this.onMapSearchDataRequest(value)}
          filterCategories={this.state.filteredCategories}
        />
        <div
          className={
            "content " + (this.shouldAllowTouch() ? "shouldAllowTouch" : "")
          }
        >
          <div className="drawer">
            {this.state.appType === "ifla" ? <IFLAInfo /> : null}
            <InfoOverlay
              isLoading={this.state.loadingPoint}
              page={this.state.selectedPoint}
              onClose={() => {
                this.unsetSelectedMarker();
              }}
            />
            <MapFilter
              title="IFLA 2019 - Walks and Talks"
              isVisible={this.state.isFilterVisible}
              categories={this.state.filterCategories}
              onFilterToggleClick={() => this.onFilterToggleClick()}
              onFilterChanged={(filter) => this.onFilter(filter)}
            />
          </div>
        </div>
        <OsloMap
          onMapClick={(e) => this.onMapClick(e)}
          onMapLoad={this.onMapLoad}
          style={this.state.style}
          center={this.state.center}
          zoom={this.state.zoom}
          onMarkerClick={this.onMarkerClick}
          geoJson={this.state.pois}
          panTo={(center, zoom) => {
            this.setState({ center: center, zoom: zoom });
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(IFLAMap);
