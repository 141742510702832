import React from "react";

import "./MapFilter.css";
import "./IFLAInfo.css";

class IFLAInfo extends React.Component {
  categoryElement(category) {
    return (
      <div
        key={category.categoryId}
        onClick={() => this.props.onFilterChanged(category)}
        className={category.selected ? "filterElement filterSelected" : "filterElement"}
      >
        <span>{category.title}</span>
      </div>
    );
  }

  render() {
    return (
      <div className={"iflaFilter filterContainer box" + (!this.props.isVisible ? " hidden" : "")}>
        {this.props.categories ? (
          <div>
            <div className="titleContainer">
              <h3>Filter</h3>
              <span onClick={() => this.props.onFilterToggleClick()} className="collapseToggle mobile">
                <span>Lukk</span>
                <i className="fas fa-times" />
              </span>
            </div>
            <div className="filterElements">
              {this.props.categories.map(category => {
                return this.categoryElement(category);
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default IFLAInfo;
