/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Header.css";
import SearchBar from "./SearchBar";
import { withTranslation } from "react-i18next";

class Header extends React.Component {
  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
  };

  render() {
    return (
      <header className="header">
        <a className="headerLink" href="/">
          <img
            alt=""
            className="headerImage"
            src={require("../assets/images/bykart-logo.svg")}
          />
        </a>
        <a
          target="_blank"
          href="https://www.oslobyleksikon.no"
          className="header-button" rel="noreferrer"
        >
          Oslo byleksikon <i className="fa fa-external-link-alt"></i>
        </a>
        {this.props.i18n.language === "no" ? (
          <a
            onClick={() => this.changeLanguage("en")}
            className="header-button language-switch"
          >
            English <i className="fa fa-globe-europe"></i>
          </a>
        ) : (
          <a
            onClick={() => this.changeLanguage("no")}
            className="header-button language-switch"
          >
            Norsk <i class="fa fa-globe-europe"></i>
          </a>
        )}
        <div className="searchBar-wrapper">
          <SearchBar
            onFilterToggleClick={() => this.props.onFilterToggleClick()}
            onPoiSelected={(poi) => this.props.onSearchPoiSelected(poi)}
            onMapSearchDataRequest={(value) =>
              this.props.onMapSearchDataRequest(value)
            }
            filterCategories={this.props.filterCategories}
            filterTitle={this.props.filterTitle}
          />
        </div>
      </header>
    );
  }
}

export default withTranslation()(Header);
