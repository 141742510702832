import React from "react";
import "./SearchBar.css";
import Autosuggest from "react-autosuggest";
import { withTranslation } from "react-i18next";

const getSuggestionValue = (result) => result.title;

// Use your imagination to render suggestions.
const renderSuggestion = (result) => (
  <div className="resultItem">{result.title}</div>
);
const renderSectionTitle = (section) => {
  if (section.results.length === 0) {
    return;
  }

  return <div className="resultSectionTitle">{section.title}</div>;
};
const getSectionSuggestions = (section) => {
  return section.results;
};

class SearchBar extends React.Component {
  state = {
    results: [],
    value: "",
  };

  onSuggestionsFetchRequested({ value }) {
    let mapPoints = this.props.onMapSearchDataRequest(value);
    this.performSearch(value).then((response) => {
      this.setState({
        results: [
          {
            title: this.props.t("I kart"),
            results: mapPoints,
          },
          {
            title: this.props.t("Oslo byleksikon"),
            results: response,
          },
        ],
      });
    });
  }

  performSearch(value) {
    let url =
      "https://oslobyleksikon.no/api.php?action=opensearch&format=json&formatversion=2&namespace=0&limit=10&suggest=true&origin=*";
    url += "&search=" + value;
    return fetch(url, {
      headers: {
        Accept: "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let textResults = response[1];
        return textResults.map((result, idx) => {
          return {
            title: result,
            url: response[3][idx],
            type: "wiki",
          };
        });
      });
  }

  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
  }

  onSuggestionsClearRequested() {
    this.setState({
      results: [],
    });
  }
  onSuggestionSelected(e, s) {
    this.setState({ value: "" });
    let { suggestion } = s;
    if (suggestion.type === "map") {
      this.props.onPoiSelected(suggestion);
    } else if (suggestion.type === "wiki") {
      window.open(suggestion.url, "_blank");
    }
  }

  render() {
    const { value, results } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: this.props.t("Søk i Oslo bykart"),
      value,
      onChange: (event, { newValue }) => this.onChange(event, { newValue }),
    };
    return (
      <div className="searchBar box">
        <i className="searchIcon fas fa-search" />
        <Autosuggest
          suggestions={results}
          multiSection={true}
          onSuggestionsFetchRequested={(value) =>
            this.onSuggestionsFetchRequested(value)
          }
          onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
          onSuggestionSelected={(e, s) => this.onSuggestionSelected(e, s)}
          getSuggestionValue={getSuggestionValue}
          getSectionSuggestions={getSectionSuggestions}
          renderSuggestion={renderSuggestion}
          renderSectionTitle={renderSectionTitle}
          inputProps={inputProps}
          focusInputOnSuggestionClick={false}
        />
        {this.props.filterCategories.length > 0 ? (
          <button
            onClick={this.props.onFilterToggleClick}
            className="filterButton filtered mobile"
          >
            <i className="fas fa-filter" />
            {this.props.filterTitle} ({this.props.filterCategories.length})
          </button>
        ) : (
          <button
            onClick={this.props.onFilterToggleClick}
            className="filterButton mobile"
          >
            <i className="fas fa-filter" />
            {this.props.filterTitle}
          </button>
        )}
      </div>
    );
  }
}

export default withTranslation()(SearchBar);
