class Api {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    //this.login();
  }

  login() {
    let url =
      "https://oslobyleksikon.dev/api.php?action=query&format=json&meta=tokens&type=login&origin=" + window.origin;
    //let url = this.baseUrl + "/api.php?action=query&format=json&meta=tokens&type=login&origin="+window.origin;
    fetch(url, {
      headers: {
        Accept: "application/json; charset=UTF-8"
      },
      mode: "cors",
      credentials: "same-origin"
    })
      .then(response => response.json())
      .then(response => response.query.tokens.logintoken)
      .then(token => {
        console.log("Token: " + token);
        token = token.substring(0, token.length - 1);
        console.log("Token: " + token);
        return fetch(`https://oslobyleksikon.dev/api.php?action=login&format=json&origin=${window.origin}&*`, {
          headers: {
            Accept: "application/json; charset=UTF-8"
          },
          mode: "cors",

          credentials: "same-origin",
          method: "POST",
          body: JSON.stringify({
            lgtoken: token,
            lgname: "mapbot",
            lgpassword: ""
          })
        });
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  getSubcategories(categoryTitle, categoryId) {
    let url =
      `${this.baseUrl}/api.php?action=query&generator=categorymembers&gcmtitle=${categoryTitle}&format=json&gcmprop=ids|type|title&gcmlimit=500&prop=coordinates&origin=${origin}`;
    return new Promise((resolve, reject) => {
      fetch(url, {
        headers: {
          Accept: "application/json; charset=UTF-8"
        }
      })
        .then(response => response.json())
        .then(response => {
          if (!response.hasOwnProperty("query")) {
            return resolve([]);
          }
          let members = response.query.pages;
          let categories = Object.keys(members).map(function(key) {
            return {
              title: members[key].title.replace("Kategori:", ""),
              categoryId: members[key].pageid
            };
          });

          resolve(categories);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getCategoryPagesDeep(categoryTitle = null, categoryId = null, mainCat = null, initialCat = null) {
    if (!initialCat) {
      initialCat = categoryTitle;
    }

    return new Promise((resolve, reject) => {
      this.getCategoryPages(categoryTitle, categoryId).then(pages => {
        let returnPages = [];
        let categoryPromises = [];

        for (const key of Object.keys(pages)) {
          let page = pages[key];
          if (page.title.includes("Kategori:")) {
            if (initialCat === categoryTitle) {
              mainCat = page.title.replace("Kategori:", "");
            }

            categoryPromises.push(this.getCategoryPagesDeep(page.title, page.pageid, mainCat, initialCat));
          } else if (page.hasOwnProperty("coordinates")) {
            page.mainCat = mainCat;
            returnPages.push(page);
          }
        }

        return Promise.all(categoryPromises).then(pages => {
          returnPages.push(...pages);
          resolve(returnPages.flat());
        });
      });
    });
  }

  getCategoryPages(categoryTitle = null, categoryId = null, cont = null, pages = []) {
    let limit = 500;
    let url =
      `${this.baseUrl}/api.php?action=query&generator=categorymembers&format=json&gcmprop=ids|type|title&prop=coordinates&gcmlimit=${limit}&colimit=${limit}&origin=${window.origin}`;

    if (categoryId) {
      url += "&gcmpageid=" + categoryId;
    } else {
      url += "&gcmtitle=" + categoryTitle;
    }

    if (cont) {
      url += "&gcmcontinue=" + cont;
    }

    return new Promise((resolve, reject) => {
      fetch(url, {
        headers: {
          Accept: "application/json; charset=UTF-8"
        }
      })
        .then(response => response.json())
        .then(response => {
          if (!response.hasOwnProperty("query")) {
            return resolve([]);
          }

          let responsePages = Object.keys(response.query.pages).map(function(key) {
            return response.query.pages[key];
          });

          pages = pages.concat(responsePages);

          if (response.hasOwnProperty("continue") && response.continue.hasOwnProperty("gcmcontinue")) {
            return this.getCategoryPages(categoryTitle, categoryId, response.continue.gcmcontinue, pages).then(
              result => {
                resolve(result);
              }
            );
          } else {
            return resolve(pages);
          }
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  }

  getSectionPoisForPage(page) {
    let url = `${this.baseUrl}/api.php?action=parse&prop=sections|text|wikitext&format=json&pageid=${page.pageid}&origin=${window.origin}`;
    return new Promise((resolve, reject) => {
      fetch(url, {
        headers: {
          Accept: "application/json; charset=UTF-8"
        }
      })
        .then(response => response.json())
        .then(response => response.parse)
        .then(parsed => {
          if (!page.hasOwnProperty("coordinates")) {
            return resolve([]);
          }
          resolve(
            parsed.sections.map(section => {
              let coordinates = page.coordinates.find(c => {
                if (c.name === section.line) {
                  return true;
                }
                return false
              }).filter(Boolean);

              return {
                title: section.line,
                anchor: section.anchor,
                offset: section.byteoffset,
                coordinates: coordinates,
                section: section.index,
                page: page,
                color: "#fff"
              };
            })
          );
        });
    });
  }

  getPagePoisInCategory(category, cont = null) {
    let url =
      this.baseUrl +
      `/api.php?action=query&generator=categorymembers&format=json&gcmprop=ids|type|title&gcmlimit=5000&colimit=500&prop=coordinates&coprop=name|type&coprimary=all&gcmtype=page&origin=${window.origin}&gcmtitle=${category}`;
    if (cont) {
      url += "&cocontinue=" + cont;
    }

    return new Promise((resolve, reject) => {
      fetch(url, {
        headers: {
          Accept: "application/json; charset=UTF-8"
        }
      })
        .then(response => response.json())
        .then(response => {
          if (!response.hasOwnProperty("query")) {
            return resolve([]);
          }

          let responsePages = Object.keys(response.query.pages).map(function(key) {
            return response.query.pages[key];
          });

          let poiPromises = [];

          responsePages.forEach(page => {
            poiPromises.push(this.getSectionPoisForPage(page));
          });

          Promise.all(poiPromises).then(pages => {
            resolve(pages.flat());
          });
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  }

  getPois(categoryTitle) {
    return new Promise((resolve, reject) => {
      this.getCategoryPagesDeep(categoryTitle)
        .then(pages => {
          let pois = pages.map(page => {
            return {
              type: "Feature",
              properties: {
                title: page.title,
                pageid: page.pageid,
                mainCat: page.mainCat
              },
              geometry: {
                type: "Point",
                coordinates: [page.coordinates[0].lon, page.coordinates[0].lat]
              }
            };
          });

          resolve({
            type: "FeatureCollection",
            features: pois
          });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getSection(pageId, section) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.baseUrl}/api.php?action=parse&pageid=${pageId}&format=json&prop=extracts|images|info|imageinfo&exintro=true&exchars=200&inprop=url&imlimit=1&origin=${window.origin}`,
        {
          headers: {
            Accept: "application/json; charset=UTF-8"
          }
        }
      )
        .then(response => response.json())
        .then(response => response.query.pages[pageId])
        .then(response => {
          let page;
          let image;

          if (!response.images) {
            page = {
              text: response.extract,
              image: null,
              title: response.title,
              pageid: response.pageid,
              url: response.fullurl
            };

            resolve(page);
          } else {
            image = response.images[0];
            this.fetchImageInfo(image.title).then(imageResponse => {
              let imageInfo = Object.values(imageResponse.query.pages)[0].imageinfo[0];

              page = {
                text: response.extract,
                image: imageInfo,
                title: response.title,
                pageid: response.pageid,
                url: response.fullurl
              };

              resolve(page);
            });
          }
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  getPage(pageId) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.baseUrl}/api.php?action=query&pageids=${pageId}&format=json&prop=extracts|images|info|imageinfo&exintro=true&exchars=200&inprop=url&imlimit=1&origin=${window.origin}`,
        {
          headers: {
            Accept: "application/json; charset=UTF-8"
          }
        }
      )
        .then(response => response.json())
        .then(response => response.query.pages[pageId])
        .then(response => {
          let page;
          let image;

          if (!response.images) {
            page = {
              text: response.extract,
              image: null,
              title: response.title,
              pageid: response.pageid,
              url: response.fullurl
            };

            resolve(page);
          } else {
            image = response.images[0];
            this.fetchImageInfo(image.title).then(imageResponse => {
              let imageInfo = Object.values(imageResponse.query.pages)[0].imageinfo[0];

              page = {
                text: response.extract,
                image: imageInfo,
                title: response.title,
                pageid: response.pageid,
                url: response.fullurl
              };

              resolve(page);
            });
          }
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  fetchImageInfo(imageName) {
    imageName = encodeURIComponent(imageName);
    let url =
      `${this.baseUrl}/api.php?action=query&format=json&prop=imageinfo&titles=${imageName}&origin=${window.origin}&iiurlwidth=500&iiprop=url`;
    return fetch(url, {
      headers: {
        Accept: "application/json; charset=UTF-8"
      }
    })
      .then(response => response.json())
      .catch(error => {
        console.log(error);
      });
  }
}

export default Api;
