import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./assets/locales/en.json";
import translationNO from "./assets/locales/no.json";

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  no: {
    translation: translationNO
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "no",
    lng: "no",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
