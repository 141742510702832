import React from "react";
import "./App.css";
import mapboxgl from "mapbox-gl";
import { isMobileOnly } from "react-device-detect";
import Api from "./MWApi";
import ReactGA from "react-ga";

import OsloMap from "./OsloMap";
import InfoOverlay from "./components/InfoOverlay";
import MapFilter from "./components/MapFilter";
import Header from "./components/Header";
import IFLAInfo from "./components/IFLAInfo";
import LoadingIndicator from "./components/LoadingIndicator";
import AddToHome from "./components/AddToHome";
import { withTranslation } from "react-i18next";

class App extends React.Component {
  initialZoomLevel = [13];
  map = null;
  api = null;

  state = {
    appLoading: true,
    appType: "regular",
    pois: { type: "FeatureCollection", features: [] },
    allPois: { type: "FeatureCollection", features: [] },
    selectedMarker: null,
    center: [10.752245, 59.913868],
    zoom: this.initialZoomLevel,
    mapType: "map",
    style: "mapbox://styles/jensen2k/cjz8jkmch0g6c1cqnkmbz5vd8",
    selectedPoint: null,
    loadingPoint: false,
    filterCategories: [],
    filteredCategories: [],
    currentLang: "no",
    isFilterVisible: isMobileOnly ? false : true,
  };

  componentDidMount() {
    this.api = new Api("https://oslobyleksikon.no");
    this.fetchPoisForCategory("Kategori:Kart");

    ReactGA.initialize("UA-117924975-2", {
      debug: true,
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.i18n.language !== this.state.currentLang) {
      this.setState({ currentLang: this.props.i18n.language });
      this.fetchPoisForLanguage(this.props.i18n.language);
    }
  }

  fetchPoisForLanguage(lng) {
    if (lng === "no") {
      this.fetchPoisForCategory("Kategori:Kart");
    } else if (lng === "en") {
      this.fetchPoisForCategory("Kategori:ENGLISH");
    }
  }

  fetchPoisForCategory(cat) {
    this.setState({ appLoading: true });
    this.api.getPois(cat).then((pois) => {
      this.setState({ pois: pois, allPois: pois, appLoading: false });

      /*
       * Highlighting, selecting and zooming to POI if it's a link to it
       */
      if (this.props.match.params.pageId) {
        
        this.selectPoiWithPageId(this.props.match.params.pageId);
      }
    });

    this.fetchFilterCategories(cat);
  }

  selectPoiWithPageId(pageId) {
    let linkedPoi = this.state.pois.features.filter((p) => {
      if (p.properties.pageid === parseInt(pageId)) {
        return true;
      }
      return false;
    });

    if (linkedPoi.length > 0) {
      linkedPoi = linkedPoi[0];
      this.setSelectedFeature(linkedPoi);
      this.zoomToFeature(linkedPoi);
    }
  }

  fetchFilterCategories(cat) {
    this.api.getSubcategories(cat).then((categories) => {
      this.setState({ filterCategories: categories });
    });
  }

  onMapSearchDataRequest(value) {
    let pois = this.state.allPois.features
      .map((poi, idx) => {
        return {
          title: poi.properties.title,
          url: "url" + idx,
          type: "map",
          pageid: poi.properties.pageid,
          coordinates: poi.geometry.coordinates,
        };
      })
      .filter((poi) => {
        return poi.title.toLowerCase().startsWith(value.toLowerCase());
      });

    return pois ? pois : [];
  }

  onFilter(filter) {
    this.unselectMap(this.map);
    this.setState({
      selectedMarker: null,
      selectedPoint: null,
    });

    let categories = this.state.filterCategories.map((cat) => {
      if (cat === filter) {
        cat.selected = !cat.selected;
      }

      return cat;
    });

    let filteredCategories = categories.filter((cat) => {
      return cat.selected;
    });

    this.setState({
      filterCategories: categories,
      filteredCategories: filteredCategories,
    });

    if (filteredCategories.length > 0) {
      let filteredCategoriesTitles = filteredCategories.map((f) => {
        return f.title;
      });

      let filteredPois = this.state.allPois.features.filter((poi) => {
        if (filteredCategoriesTitles.includes(poi.properties.mainCat)) {
          return true;
        } else {
          return false;
        }
      });

      let geoJson = {
        type: "FeatureCollection",
        features: filteredPois,
      };
      this.setState({ pois: geoJson });
    } else {
      this.setState({ pois: this.state.allPois });
    }
  }

  onMapClick(map) {
    if (this.state.selectedPoint != null) this.unsetSelectedMarker();
    this.unselectMap(map);
  }

  unsetSelectedMarker() {
    this.setState({ selectedPoint: null, selectedMarker: null });
  }

  onSearchPoiSelected = (poi) => {
    let coordinates = [poi.coordinates[0], poi.coordinates[1]];

    this.setState({
      loadingPoint: true,
      selectedMarker: poi.pageid,
      center: coordinates,
      zoom: [16],
    });

    this.setState({ loadingPoint: true });
    this.api.getPage(poi.pageid).then((page) => {
      this.setState({ selectedPoint: page });
      this.setState({ loadingPoint: false });
    });
  };

  onMapLoad = (map) => {
    this.map = map;
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      })
    );
  };

  onFilterToggleClick() {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  }

  unselectMap = (map) => {
    this.props.history.push("/");
    if (typeof map.getLayer("selectedPoint") !== "undefined") {
      map.removeLayer("selectedPoint");
      map.removeSource("selectedPoint");
    }
  };

  zoomToFeature = (feature) => {
    let coordinates = feature.geometry.coordinates;
    this.setState({ center: coordinates, zoom: [14] });
  };

  setURLToPageId = (pageId) => {
    this.props.history.push("/point/" + pageId);
  };

  setSelectedFeature = (feature, map = this.map) => {
    this.unselectMap(map);

    this.setURLToPageId(feature.properties.pageid);

    map.addSource("selectedPoint", {
      type: "geojson",
      data: {
        type: feature.type,
        geometry: feature.geometry,
        properties: feature.properties,
      },
    });
    map.addLayer({
      id: "selectedPoint",
      type: "circle",
      source: "selectedPoint",
      paint: {
        "circle-color": "red",
        "circle-radius": 8,
        "circle-stroke-width": 4,
        "circle-stroke-color": "#fff",
      },
    });

    let pageid = feature.properties.pageid;

    this.setState({
      loadingPoint: true,
      selectedMarker: pageid,
    });

    ReactGA.modalview("/point/" + pageid);

    this.api.getPage(pageid).then((page) => {
      this.setState({ selectedPoint: page });
      this.setState({ loadingPoint: false });
    });
  };

  onMarkerClick = (e) => {
    let map = e.target;
    var feature = e.features[0];

    this.setSelectedFeature(feature, map);
  };

  shouldAllowTouch = () => {
    if (this.state.isFilterVisible || this.state.selectedPoint) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <div className={"App " + this.state.appType}>
        {this.state.appLoading ? <LoadingIndicator /> : null}
        <Header
          filterTitle="Filter"
          onFilterToggleClick={() => this.onFilterToggleClick()}
          onSearchPoiSelected={(poi) => this.onSearchPoiSelected(poi)}
          onMapSearchDataRequest={(value) => this.onMapSearchDataRequest(value)}
          filterCategories={this.state.filteredCategories}
        />
        <AddToHome></AddToHome>
        <div
          className={
            "content " + (this.shouldAllowTouch() ? "shouldAllowTouch" : "")
          }
        >
          <div className="drawer">
            {this.state.appType === "ifla" ? <IFLAInfo /> : null}
            <InfoOverlay
              isLoading={this.state.loadingPoint}
              page={this.state.selectedPoint}
              onClose={() => {
                this.unsetSelectedMarker();
              }}
            />
            <MapFilter
              title="Filter"
              isVisible={this.state.isFilterVisible}
              categories={this.state.filterCategories}
              onFilterToggleClick={() => this.onFilterToggleClick()}
              onFilterChanged={(filter) => this.onFilter(filter)}
            />
          </div>
        </div>
        <OsloMap
          onMapClick={(e) => this.onMapClick(e)}
          onMapLoad={this.onMapLoad}
          style={this.state.style}
          center={this.state.center}
          zoom={this.state.zoom}
          onMarkerClick={this.onMarkerClick}
          geoJson={this.state.pois}
          panTo={(center, zoom) => {
            this.setState({ center: center, zoom: zoom });
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(App);
