import React from "react";
import "./LoadingIndicator.css";
import { BounceLoader } from "react-spinners";
import { withTranslation } from "react-i18next";

const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
  top: 50%;
  `;

class LoadingIndicator extends React.Component {
  render() {
    return (
      <div className="loadingIndicator">
        <BounceLoader css={override} sizeUnit={"px"} size={50} color={"#fff"} loading={true} />
        <p>{this.props.t("Laster inn kart")}</p>
      </div>
    );
  }
}

export default withTranslation()(LoadingIndicator);
