import React from "react";
import { withTranslation } from "react-i18next";

import "./MapFilter.css";

class MapFilter extends React.Component {
  categoryElement(category) {
    return (
      <div
        key={category.categoryId}
        onClick={() => this.props.onFilterChanged(category)}
        className={category.selected ? "filterElement filterSelected" : "filterElement"}
      >
        <span>{category.title}</span>
      </div>
    );
  }

  render() {
    return (
      <div className={"filterContainer box" + (!this.props.isVisible ? " hidden" : "")}>
        {this.props.categories ? (
          <div>
            <div className="titleContainer">
              <h3>{this.props.title}</h3>
              <span onClick={() => this.props.onFilterToggleClick()} className="collapseToggle mobile">
                <span>{this.props.t("Lukk")}</span>
                <i className="fas fa-times" />
              </span>
            </div>
            <div className="filterElements">
              {this.props.categories.map(category => {
                return this.categoryElement(category);
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(MapFilter);
