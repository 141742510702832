import React from "react";

import "./AddToHome.css";

class AddToHome extends React.Component {
  state = {
    showInstallMessage: false
  };

  componentDidMount() {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => "standalone" in window.navigator && window.navigator.standalone;

    const isHidden = () => localStorage.getItem("hide-addToHomeBanner");

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode() && !isHidden()) {
      this.setState({ showInstallMessage: true });
    } else {
      this.setState({ showInstallMessage: false });
    }
  }

  hideBanner = () => {
    localStorage.setItem("hide-addToHomeBanner", true);
    this.setState({ showInstallMessage: false });
  };

  render() {
    if (!this.state.showInstallMessage) {
      return null;
    }

    return (
      <div className="addToHome-wrapper">
        <h3>
          Legg til på telefonen <i onClick={this.hideBanner} className="fas fa-times" />
        </h3>
        <p>
          Klikk <i className="shareSheet-icon"></i> og deretter "Add to Home Screen" eller "Legg til på hjem skjerm" for
          å legge til på din telefon
        </p>
      </div>
    );
  }
}

export default AddToHome;
