import React from "react";
import PropTypes from "prop-types";
import ReactMapboxGl, { ZoomControl, Layer, GeoJSONLayer } from "react-mapbox-gl";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const Map = ReactMapboxGl({
  accessToken: "pk.eyJ1IjoiamVuc2VuMmsiLCJhIjoiaWNkaGRpOCJ9.bfmW2N_lvSae8Ys9iDZDxQ"
});

class OsloMap extends React.Component {
  static propTypes = {
    zoom: PropTypes.array,
    center: PropTypes.array,
    onMapLoad: PropTypes.func,
    geoJson: PropTypes.object,
    onMarkerMouseEnter: PropTypes.func,
    onMarkerMouseLeave: PropTypes.func,
    onMarkerClick: PropTypes.func,
    onMapClick: PropTypes.func,
    panTo: PropTypes.func
  };

  static style = "mapbox://styles/jensen2k/cjz8jkmch0g6c1cqnkmbz5vd8";

  state = {
    mapType: "map",
    style: "mapbox://styles/jensen2k/cjz8jkmch0g6c1cqnkmbz5vd8"
  };

  mapTypeControl = () => {
    return (
      <div className="mapTypeControl">
        <span
          className={this.state.mapType === "map" ? "mapTypeControlButton selected" : "mapTypeControlButton"}
          onClick={evt => this.setMapType("map")}
        >
          {this.props.t("Kart")}
        </span>
        <span className="mapTypeSeparator" />
        <span
          className={this.state.mapType === "satellite" ? "mapTypeControlButton selected" : "mapTypeControlButton"}
          onClick={evt => this.setMapType("satellite")}
        >
          {this.props.t("Satelitt")}
        </span>
      </div>
    );
  };

  setMapType = mapType => {
    this.setState({ mapType: mapType });
    switch (mapType) {
      case "map":
        this.setState({ style: "mapbox://styles/jensen2k/cjpdxzuyy12vi2rps2ynv99fj" });
        break;
      case "satellite":
        this.setState({ style: "mapbox://styles/mapbox/satellite-v9" });
        break;
      default:
    }
  };

  onMarkerMouseEnter = e => {
    let map = e.target;
    map.getCanvas().style.cursor = "pointer";
  };

  onMarkerMouseLeave = e => {
    let map = e.target;
    map.getCanvas().style.cursor = "";
  };

  getPointColor = point => {
    if (this.props.location.pathname === "/walkstalks") {
      //ColorFromString(point);
    } else {
      return "#11b4da";
    }
  };

  onClusterClick = e => {
    let map = e.target;
    let features = map.queryRenderedFeatures(e.point, { layers: ["cluster_layer"] });
    let clusterId = features[0].properties.cluster_id;
    map.getSource("points").getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return;

      this.props.panTo(features[0].geometry.coordinates, [zoom]);
    });
  };

  onMapLoad(map) {
    this.props.onMapLoad(map);
  }

  componentDidMount() {}

  render() {
    return (
      <Map
        onClick={e => this.props.onMapClick(e)}
        onStyleLoad={map => this.onMapLoad(map)}
        style={this.state.style}
        center={this.props.center}
        zoom={this.props.zoom}
        containerStyle={{
          height: "100%",
          width: "100%",
          position: "absolute"
        }}
      >
        <GeoJSONLayer
          id="points"
          data={this.props.geoJson}
          sourceOptions={{ cluster: true, clusterMaxZoom: 12, clusterRadius: 45 }}
        />
        <Layer
          id="cluster_layer"
          sourceId="points"
          filter={["has", "point_count"]}
          paint={{
            "circle-color": "rgba(17, 171, 213, 0.8)",
            "circle-stroke-width": {
              property: "point_count",
              type: "interval",
              stops: [[3, 2], [4, 4], [10, 6]]
            },
            "circle-stroke-color": "#fff",
            "circle-radius": {
              property: "point_count",
              type: "interval",
              stops: [[3, 15], [4, 20], [10, 30]]
            }
          }}
          onMouseEnter={this.onMarkerMouseEnter}
          onMouseLeave={this.onMarkerMouseLeave}
          onClick={this.onClusterClick}
          type="circle"
        />
        <Layer
          id="cluster_count"
          sourceId="points"
          filter={["has", "point_count"]}
          layout={{
            "text-field": "{point_count_abbreviated}",
            "text-font": ["Work Sans SemiBold", "DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": {
              property: "point_count",
              type: "interval",
              stops: [[13, 16], [16, 18], [20, 20]]
            }
          }}
          paint={{
            "text-halo-width": 2,
            "text-halo-blur": 0,
            "text-halo-color": "rgba(0,0,0,.25)",
            "text-color": "#fff"
          }}
          onMouseEnter={this.onMarkerMouseEnter}
          onMouseLeave={this.onMarkerMouseLeave}
          type="symbol"
        />
        {this.props.location.pathname === "/walkstalks" ? (
          <Layer
            id="point_layer"
            type="circle"
            sourceId="points"
            filter={["!", ["has", "point_count"]]}
            paint={{
              "circle-color": ["get", "color"],
              "circle-radius": 6,
              "circle-stroke-width": 3,
              "circle-stroke-color": "#fff"
            }}
            onMouseEnter={this.onMarkerMouseEnter}
            onMouseLeave={this.onMarkerMouseLeave}
            onClick={this.props.onMarkerClick}
          />
        ) : (
          <Layer
            id="point_layer"
            type="circle"
            sourceId="points"
            filter={["!", ["has", "point_count"]]}
            paint={{
              "circle-color": "#11b4da",
              "circle-radius": 6,
              "circle-stroke-width": 3,
              "circle-stroke-color": "#fff"
            }}
            onMouseEnter={this.onMarkerMouseEnter}
            onMouseLeave={this.onMarkerMouseLeave}
            onClick={this.props.onMarkerClick}
          />
        )}
        <Layer
          type="symbol"
          id="title_layer"
          sourceId="points"
          filter={["!", ["has", "point_count"]]}
          minZoom={13}
          paint={{
            "text-halo-width": 2,
            "text-halo-blur": 0,
            "text-halo-color": "#f0e9d8",
            "text-color": "#3133a0"
          }}
          layout={{
            "text-field": "{title}",
            "text-font": ["Work Sans Regular", "DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": {
              property: "point_count",
              type: "interval",
              stops: [[13, 16], [16, 16], [20, 13]]
            },
            "text-anchor": "left",
            "text-padding": 5,
            "text-offset": [1, 0],
            "text-justify": "auto"
          }}
          onMouseEnter={this.props.onMarkerMouseEnter}
          onMouseLeave={this.props.onMarkerMouseLeave}
          onClick={this.props.onMarkerClick}
        />

        {/*this.mapTypeControl()*/}
        <ZoomControl style={{ zIndex: 9, bottom: 40 }} position={"bottom-left"} />
      </Map>
    );
  }
}

export default withRouter(withTranslation()(OsloMap));
