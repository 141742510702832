import React from "react";
import App from "./App";
import IFLAMap from "./IFLAMap";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { withTranslation } from "react-i18next";

class AppRouter extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={App} />
          <Route path="/point/:pageId" exact component={App} />
          <Route path="/walkstalks/" component={IFLAMap} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default withTranslation()(AppRouter);
