import React from "react";
import { withTranslation } from "react-i18next";

import "./InfoOverlay.css";

import ContentLoader from "react-content-loader";

const InfoOverlayContentLoader = () => (
  <ContentLoader height={160} width={400} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
    <rect x="7" y="26" rx="4" ry="4" width="181" height="14" />
    <rect x="8" y="59" rx="3" ry="3" width="350" height="6" />
    <rect x="8" y="79" rx="3" ry="3" width="380" height="6" />
    <rect x="8" y="99" rx="3" ry="3" width="201" height="6" />
  </ContentLoader>
);

class InfoOverlay extends React.Component {
  state = {
    isImageLoading: true
  };

  componentDidUpdate(prevProps) {}

  render() {
    //let image = null;

    return (
      <div
        className={!this.props.page && !this.props.isLoading ? "infoOverlay box" : "infoOverlay box infoOverlay-open"}
      >
        {this.props.isLoading ? <InfoOverlayContentLoader /> : null}
        {this.props.page && !this.props.isLoading ? (
          <div className="infoOverlayInner">
            <div className="titleContainer">
              <h2>{this.props.page.title}</h2>
              <i onClick={() => this.props.onClose()} className="fas fa-times" />
            </div>
            <div className="infoOverlayContent">
              {/*this.props.page.image ? 
                            <div className='infoOverlayImage'>
                                <img src={this.props.page.image.thumburl}></img>
                        </div> : null*/}
              <span className="infoContent" dangerouslySetInnerHTML={{ __html: this.props.page.text }} />
            </div>
            <div className="moreLinkContainer">
              <a rel="noopener noreferrer" href={this.props.page.url} target="_blank">
                {this.props.t("Les mer i Oslo byleksikon")}
              </a>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(InfoOverlay);
